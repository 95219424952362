/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-scroll';
import PortfolioContext from '../../context/context';

// eslint-disable-next-line prettier/prettier



const Footer = () => {
  const { footer } = useContext(PortfolioContext);
  const { networks } = footer;


  return (
    <footer className="footer navbar-static-bottom">
      <Container>
        <span className="back-to-top">
          <Link to="about" smooth duration={3000}>
            <i className="fa fa-refresh fa-spin fa-3x fa-fw" aria-hidden="true" />
            <br />
            View Again
            <br />
            <br />
          </Link>
        </span>
        <div className="social-links">
          {networks &&
            networks.map((network) => {
              const { id, name, url } = network;
              return (
                <a
                  key={id}
                  href={url || 'https://www.linkedin.com/in/cory-wong-487b08131/'}
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label={name}
                >
                  <i className={`fa fa-${name || 'refresh'} fa-inverse`} />
                </a>
              );
            })}
        </div>
        <hr />
        <p className="footer__text">
          © {new Date().getFullYear()} - by{' '}
          <a href="https://www.linkedin.com/in/cory-wong-487b08131/" target="_blank" rel="noopener noreferrer">
            Cory Wong
          </a>
        </p>

      </Container>
    </footer>
  );
};

export default Footer;
