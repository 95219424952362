/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import ProjectImg from '../Image/ProjectImg';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);
  
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          <Title title="Work Experience & Education" />
          {projects.map((project) => {
            const { title, title1, title2, title3, datesworked, info, info2, info3, info4, info5, info6, info7, url, repo, img, id } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <Fade
                    top={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={150}
                    distance="30px"
                  >
                    <div className="project-wrapper__text">
                      <h1 className="project-wrapper__text-title">{title1 || ''}</h1>

                      <div>
                        <p>
                          <p className="mb-4">{title2 || ''}</p>
                          <p className="mb-4">{title3 || ''}</p>
                          <h1 className="mb-4">{title || ''}</h1>
                          <p className="mb-4">{datesworked || ''}</p>
                          <br />
                          {info ||
                            ''}
                        </p>
                        <br />
                        <p className="mb-4">{info2 || ''}</p>
                        <ul>
                          <li className="project-wrapper_subheader">{info3 || ''}</li>
                          <li className="project-wrapper_subheader">{info4 || ''}</li>
                          <li className="project-wrapper_subheader">{info5 || ''}</li>
                          <li className="project-wrapper_subheader">{info6 || ''}</li>
                          <li className="project-wrapper_subheader">{info7 || ''}</li>
                        </ul>
                      </div>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cta-btn cta-btn--hero"
                        href={url || '#!'}
                      >
                        Visit
                      </a>
                      <hr />
                      {repo && (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          className="cta-btn text-color-main"
                          href={repo}
                        >
                          Source Code
                        </a>
                      )}
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    top={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance="30px"
                  >
                    <div className="project-wrapper__image">
                      <a
                        href={url || '#!'}
                        target="_blank"
                        aria-label="Project Link"
                        rel="noopener noreferrer"
                      >
                        <Tilt
                          options={{
                            reverse: true,
                            max: 17,
                            perspective: 1000,
                            scale: 1.10,
                            speed: 5000,
                            transition: true,
                            axis: null,
                            reset: true,
                            easing: 'cubic-bezier(.03,.98,.52,.99)',
                          }}
                        >
                          <div data-tilt className="thumbnail rounded">
                            <ProjectImg alt={title} filename={img} />
                          </div>
                        </Tilt>
                      </a>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default Projects;
