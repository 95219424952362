/* eslint-disable prettier/prettier */
import React from 'react';
import { Helmet } from 'react-helmet';
import App from '../components/App';
import { headData } from '../mock/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  const { title, lang, description } = headData;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="keywords" content="cory wong, rbc, ryerson, btm, sql, python, data, analysis, analytics, processes, projects" />
        <title>{title || "Cory Wong | RBC Senior Business Analyst | Ryerson BTM"}</title>
        <html lang={lang || 'en'} />
        <meta name="description" content={description || "My name is Cory Wong. I am currently a senior business analyst at RBC. I hold an undergraduate degree in Business Technology Management from Ryerson University."} />
      </Helmet>
      <App />
    </>
  );
};
